const navbar = {
    namespaced: true,
    state: () => ({
        is_visible: true,
    }),
    getters: {
        is_visible: state => state.is_visible,
    },
    mutations: {
        set_visible(state, value) {
            state.is_visible = value;
        }
    }
};

const loading_animation = {
    namespaced: true,
    state: () => ({
        is_loading: false,
    }),
    getters: {
        is_loading: state => state.is_loading,
    },
    mutations: {
        set_loading(state, value) {
            state.is_loading = value;
        }
    }
}

const footer = {
    namespaced: true,
    state: () => ({
        trigger_recalc: 0,
    }),
    getters: {
        trigger_recalc: state => state.trigger_recalc,
    },
    mutations: {
        trigger_recalc(state) {
            state.trigger_recalc++;
        }
    }
}

const auth = {
    namespaced: true,
    state: () => ({
        auth: null,
    }),
    getters: {
        is_authenticated: state => {
            if (state.auth !== null) {
                if (Date.parse(state.auth.valid_until) > new Date())
                    return true;
                else {
                    state.auth = null;
                    return false;
                }
            }
            return false;
        },
        get_authentication: state => {
            return state.auth;
        }
    },
    mutations: {
        set_authentication(state, value) {
            state.auth = value.auth;
            const storage = value.rememberme ? localStorage : sessionStorage;
            storage.setItem("auth", JSON.stringify(value.auth));
        },
        init_store(state) {
            if (sessionStorage.getItem("auth") !== null)
                state.auth = JSON.parse(sessionStorage.getItem("auth"));
            state.auth = JSON.parse(localStorage.getItem("auth"));
        },
        logout(state) {
            state.auth = null;
            sessionStorage.removeItem("auth");
            localStorage.removeItem("auth");
        }
    }
}

module.exports = {
    modules: {
        navbar,
        auth,
        loading_animation,
        footer
    }
};