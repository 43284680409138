module.exports = {
    smoothScroll(query, ignore_navbar=false) {
        if (ignore_navbar)
            document.querySelector(query).scrollIntoView({
                behavior: 'smooth'
            });
        else {
            console.log(document.querySelector('.navbar'))
            window.scrollTo({
                top: document.querySelector(query).getBoundingClientRect().top + window.pageYOffset - document.querySelector('.navbar').getBoundingClientRect().height,

                behavior: 'smooth'
            })
        }
    },
};