<template>
    <b-list-group-item class="wrapper">
        <b-img rounded fluid :src="this.$config.backend_host + user_info.avatar_url"/>
        <div class="name">
            <small>Logged in as:</small>
            <p>
              <span v-for="g in visible_groups" :key="g" class="group-icon">
                <font-awesome-icon :id="'icon-' + g" :icon="group_icons[g].icon"/>
                <b-tooltip :target="'icon-' + g">{{ group_icons[g].title }}</b-tooltip>
              </span>
              {{ user_info.username }}
            </p>
        </div>
        <b-button @click="clickLogout('/auth/logout?redirect=' + encodedRoute)"><font-awesome-icon :icon="faSignOutAlt"/></b-button>
    </b-list-group-item>
</template>

<script>
import {BButton, BImg, BListGroupItem, BTooltip} from "bootstrap-vue";
import FontAwesomeIcon from "@fortawesome/vue-fontawesome/src/components/FontAwesomeIcon";
import { faSignOutAlt, faTools } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

export default {
    name: "NavItemUserProfile",
    components: {
        BImg,
        BListGroupItem,
        BButton,
        FontAwesomeIcon,
        BTooltip,
    },
    data() {
        return {
            faSignOutAlt,
            user_info: {
                _id: null,
                username: null,
                avatar_url: null,
                groups: [],
            },
            group_icons: {
              "admin": {
                title: "Administrator",
                icon: faTools
              },
            }
        }
    },
    computed: {
        encodedRoute() {
            if (this.$route.path.startsWith("/auth/") && this.$route.query.redirect)
                return this.$route.query.redirect;
            return encodeURIComponent(this.$route.path);
        },
        authentication() {
            return this.$store.getters["auth/get_authentication"];
        },
        visible_groups() {
          return this.user_info.groups.filter(i => {
            return i in this.group_icons;
          })
        }
    },
    methods: {
        clickLogout() {
            this.$store.commit("auth/logout");
        }
    },
    mounted() {
        axios.get(`${this.$config.backend_host}/api/users/${this.authentication.user_id}`)
        .then(res => {
            this.user_info = res.data;
        })
    }
}
</script>

<style scoped>
    .group-icon {
        cursor: pointer;
    }

    .wrapper {
        padding-left: 1em;
        margin-bottom: 3em;
        text-transform: none !important;
        border-bottom: none;
        display: flex;
        align-items: center;
    }

    .wrapper img {
        max-width: 48px;
        max-height: 48px;
        margin-right: 1em;
    }

    .wrapper .name {
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        flex-grow: 1;
    }

    .name small {
        margin: 0;
        font-size: .75rem;
        color: rgba(255, 255, 255, 0.6);
        text-transform: uppercase;
    }

    .name p {
        display: block;
        margin: 0;
        font-size: 1rem;
    }
</style>