<template>
    <b-list-group-item :class="{'current': isCurrent}" :to="to" @click="$emit('click')">
        <slot>Untitled</slot>
    </b-list-group-item>
</template>

<script>
import {BListGroupItem} from 'bootstrap-vue';

export default {
    name: "NavListItem",
    components: {
        BListGroupItem
    },
    props: {
        to: String
    },
    computed: {
        isCurrent() {
            return this.$route.path === this.to || this.$route.path === this.to + "/";
        }
    }
}
</script>

<style scoped>

</style>