<template>
    <div id="app">
        <navbar/>
        <loading-screen v-if="showLoadingScreen"/>
        <router-view v-show="!showLoadingScreen"/>
        <custom-footer/>
        <cookie-consent/>
    </div>
</template>

<script>
import Navbar from "@/components/templates/navbar/";
import CustomFooter from "@/components/templates/footer";
import CookieConsent from "@/components/templates/CookieConsent";
import LoadingScreen from "@/components/templates/LoadingScreen";

export default {
    name: 'App',
    components: {
        Navbar,
        CustomFooter,
        CookieConsent,
        LoadingScreen
    },
    metaInfo: {
        title: 'default',
        titleTemplate: '%s - strukteon.net'
    },
    computed: {
        showLoadingScreen() {
            return this.$store.getters["loading_animation/is_loading"]
        }
    }
}
</script>

<style>

</style>
