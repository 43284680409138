<template>
    <b-navbar ref="navbar" :class="{'bg-dark':true,'sticky-top':true,'hidden-bar':!showBar}" type="dark" variant="info">
        <b-navbar-brand href="https://strukteon.net">Strukteon</b-navbar-brand>

        <b-navbar-nav class="ml-auto">
            <b-button variant="dark" @click="openNav"><img :src="require('@/assets/images/bars.svg')"></b-button>
        </b-navbar-nav>

        <transition name="nav-overlay">
            <div v-show="navbarVisible" class="nav-site-overlay" @click="closeNav"></div>
        </transition>


        <div class="nav-overlay-wrapper">
            <transition name="nav-wrapper">
                <div v-show="navbarVisible" class="nav-overlay">
                    <b-list-group flush>
                        <nav-list-item class="nav-close no-border">
                            <b-button variant="dark" @click="closeNav"><img :src="require('@/assets/images/close.svg')"></b-button>
                        </nav-list-item>
                        <nav-auth-item @click="closeNav" v-if="!isAuthenticated"/>
                        <nav-item-user-profile @click="closeNav" v-else/>
                        <nav-list-item to="/" @click.native="closeNav">Home</nav-list-item>
                        <nav-list-item to="/projects" @click.native="closeNav">Projects</nav-list-item>
                        <nav-list-item to="/your-ad" @click.native="closeNav">Advertise</nav-list-item>
                    </b-list-group>
                </div>
            </transition>
        </div>
    </b-navbar>
</template>

<script>
import NavListItem from "@/components/templates/navbar/NavListItem";
import {BNavbar, BNavbarBrand, BNavbarNav, BButton, BListGroup} from 'bootstrap-vue';
import NavAuthItem from "@/components/pages/authentication/NavAuthItem";
import NavItemUserProfile from "@/components/templates/navbar/NavItemUserProfile";

export default {
    name: "Navbar",
    components: {
        NavItemUserProfile,
        BNavbar,
        BNavbarBrand,
        BNavbarNav,
        BButton,
        BListGroup,
        NavListItem,
        NavAuthItem
    },
    data() {
        return {
            navbarVisible: false
        }
    },
    computed: {
        showBar() {
            return this.$store.getters["navbar/is_visible"];
        },
        isAuthenticated() {
            return this.$store.getters["auth/is_authenticated"];
        }
    },
    methods: {
        openNav() {
            this.navbarVisible = true;
            document.documentElement.style.overflow = "hidden";
        },

        closeNav() {
            this.navbarVisible = false;
            document.documentElement.style.overflow = "auto";
        }
    }
}
</script>

<style scoped>
.hidden-bar {
    background-color: transparent !important;
    pointer-events: none;
}

.hidden-bar .btn {
    pointer-events: auto;
}

.hidden-bar .navbar-brand {
    color: transparent;
}

/* transitions */
/*noinspection CssUnusedSymbol*/
.nav-overlay-enter-active, .nav-overlay-leave-active,
.nav-wrapper-enter-active, .nav-wrapper-leave-active {
    transition: all .2s;
}

/*noinspection CssUnusedSymbol*/
.nav-wrapper-enter-active, .nav-wrapper-leave-active {
    position: absolute;
    display: block;
}


/*noinspection CssUnusedSymbol*/
.nav-wrapper-enter, .nav-wrapper-leave-to {
    overflow: hidden;
    /*right: max(-25em, -80%) !important;*/
    transform: translateX(100%);
}

/*noinspection CssUnusedSymbol*/
.nav-wrapper-enter-to {
    /*right: 0;*/
    transform: translateX(0);
}

.nav-overlay-wrapper {
    pointer-events: none;
}

.nav-overlay-wrapper > *, .nav-site-overlay {
    pointer-events: initial;
}


/*noinspection CssUnusedSymbol*/
.nav-overlay-enter, .nav-overlay-leave-to {
    overflow: hidden;
    opacity: 0;
}

/*noinspection CssUnusedSymbol*/
.nav-overlay-enter-to, .nav-overlay-leave {
    opacity: 1;
}


nav {
    transition: background-color .2s;
}


/*noinspection CssUnusedSymbol*/
.navbar-brand {
    transition: color .2s;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.075em;
}

.nav-site-overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .4);
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
}

.nav-overlay {
    position: absolute;
    background-color: #343a40;
    top: 0;
    right: 0;
    height: 100vh;
    width: 25em;
    max-width: 80%;
}

/*noinspection CssUnusedSymbol*/
.nav-overlay .list-group {
    background-color: transparent;
}

.nav-overlay .list-group-item {
    text-align: left;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0.075em;
    background-color: transparent;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .2s;
    cursor: default;
}

.nav-overlay .no-border {
    border-color: transparent;
}

.nav-overlay .nav-close {
    background-color: transparent;
    text-align: right;
}

.nav-close a {
    padding: 4px 8px 6px 8px;
    border: transparent 1px;
    border-radius: 4px;
}

.nav-close a:hover {
    background-color: rgba(0, 0, 0, .2);
}

/*noinspection CssUnusedSymbol*/
.nav-overlay .current {
    color: rgba(255, 255, 255, .4);
}

.nav-overlay a:not(.current):not(.auth-btn):hover {
    background-color: rgba(0, 0, 0, .2);
    cursor: pointer;
}

.nav-overlay button:not(.auth-btn) {
    border: none;
}

.nav-overlay-hidden .nav-site-overlay {
    opacity: 0;
    pointer-events: none;
}

.nav-overlay-wrapper {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
}
</style>