<template>
    <footer class="footer">
        <!--        <br/><br/><br/><br/><br/><br/>-->
        <div class="footer-links">
            <a :href="this.$config.social_media.discord_server" decorated target="_blank">
                <h3>
                    <font-awesome-icon :icon="faDiscord"/>
                </h3>
            </a>
            <a :href="this.$config.social_media.instagram" decorated target="_blank">
                <h3>
                    <font-awesome-icon :icon="faInstagram"/>
                </h3>
            </a>
            <a :href="'mailto:' + this.$config.email_business" decorated>
                <h3>
                    <font-awesome-icon :icon="faEnvelope"/>
                </h3>
            </a>
        </div>
        <ul class="footer-bottom container">
            <footer-list-item to="/imprint">Imprint</footer-list-item>
            <footer-list-item to="/privacypolicy">Privacy Policy</footer-list-item>
            <footer-list-item to="/termsofuse">Terms of use</footer-list-item>
            <footer-list-item>(c) Strukteon {{ copyrightTime }}</footer-list-item>
        </ul>
    </footer>
</template>

<script>
import FontAwesomeIcon from "@fortawesome/vue-fontawesome/src/components/FontAwesomeIcon";
import FooterListItem from "@/components/templates/footer/FooterListItem";


import {faEnvelope} from '@fortawesome/free-regular-svg-icons'
import {faDiscord, faInstagram} from '@fortawesome/free-brands-svg-icons'

export default {
    name: "CustomFooter",
    components: {
        FooterListItem,
        FontAwesomeIcon
    },
    data() {
        return {
            faEnvelope,
            faDiscord,
            faInstagram
        }
    },
    computed: {
        copyrightTime() {
            const curYear = new Date().getFullYear();
            if (this.$config.year_created === curYear) return curYear;
            return this.$config.year_created + ' - ' + curYear;
        }
    }
}
</script>

<style scoped>
.footer {
    padding-top: 3em;
    padding-bottom: 4em;
    background-color: #2e3842;
}

.footer ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.footer li {
    text-transform: uppercase;
    color: white;
    font-size: 1em;
    letter-spacing: 0.225em;
    line-height: 1.65em;
}

.footer li {
  margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
}

.footer-links {
    display: flex;
    justify-content: center;
    padding-bottom: 2em;
}

.footer-links a + a {
    margin-left: 1em;
}
</style>