<template>
    <li>
        <router-link v-if="to" :to="computedLink" decorated>
            <slot>Untitled</slot>
        </router-link>
        <slot v-else>Untitled</slot>
    </li>
</template>

<script>
export default {
    name: "FooterListItem",
    props: {
        to: String
    },
    computed: {
        isCurrent() {
            return this.$route.path === this.to;
        },
        computedLink() {
            if (this.isCurrent) return "#!";
            return this.to;
        }
    }
}
</script>