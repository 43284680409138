const dynamic_link_pattern = new RegExp(/^\/.+$/i);

module.exports = {
    render: function (createElement) {

        if (dynamic_link_pattern.test(this.to))
            return createElement('router-link',
                {
                    props: {
                        to: this.to,
                        target: this.target,
                    }
                },
                this.$slots.default);

        return createElement('a',
            {
                attrs: {
                    href: this.to,
                    target: this.target
                }
            },
            this.$slots.default);


    },
    props: {
        to: String,
        target: String
    }
};