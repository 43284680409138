module.exports = {
    isAuthenticated() {
        const storage = sessionStorage.getItem("auth") !== null ? sessionStorage : localStorage;
        if (storage.getItem("auth") !== null) {
            if (storage.getItem("auth").valid_until > new Date())
                return true;
            else {
                storage.removeItem("auth");
                return false;
            }
        }
        return false;
    },
    getAuthentication() {
        if (sessionStorage.getItem("auth") !== null)
            return JSON.parse(sessionStorage.getItem("auth"));
        return JSON.parse(localStorage.getItem("auth"));
    },
    setAuthentication(auth, session = false) {
        console.log(auth)
        if (session)
            sessionStorage.setItem("auth", JSON.stringify(auth));
        else
            localStorage.setItem("auth", JSON.stringify(auth));
    }
}