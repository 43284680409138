import { render, staticRenderFns } from "./NavAuthItem.vue?vue&type=template&id=5fa7fd9c&scoped=true&"
import script from "./NavAuthItem.vue?vue&type=script&lang=js&"
export * from "./NavAuthItem.vue?vue&type=script&lang=js&"
import style0 from "./NavAuthItem.vue?vue&type=style&index=0&id=5fa7fd9c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa7fd9c",
  null
  
)

export default component.exports