<template>
    <b-list-group-item class="auth-wrapper no-border">
        <b-button-group>
            <b-button @click="click('/auth/login?redirect=' + encodedRoute)" variant="info" class="login-btn" :disabled="this.$route.path === '/auth/login'">Login</b-button>
            <b-button @click="click('/auth/register?redirect=' + encodedRoute)" variant="info" class="register-btn" :disabled="this.$route.path === '/auth/register'">Register
            </b-button>
        </b-button-group>
    </b-list-group-item>
</template>

<script>
import {BButton, BButtonGroup, BListGroupItem} from "bootstrap-vue";

export default {
    name: "NavAuthItem",
    components: {
        BButtonGroup,
        BListGroupItem,
        BButton,
    },
    computed: {
        encodedRoute() {
            if (this.$route.path.startsWith("/auth/") && this.$route.query.redirect)
                return this.$route.query.redirect;
            return encodeURIComponent(this.$route.path);
        }
    },
    methods: {
        click(location) {
            console.log("clicked");
            this.$router.push(location);
            this.$emit('click');
        }
    }
}
</script>

<style scoped>

.auth-wrapper > .btn:hover {
    background-color: #343a40;
}

.auth-wrapper .btn {
    width: 8em;
    text-transform: uppercase;
}

.auth-wrapper {
    display: flex;
    justify-content: center;
}

.register-btn {
    background-color: #21b2a6;
    border-color: #21b2a6;
}

.register-btn:hover {
    background-color: #188a81;
    border-color: #188a81;
}
</style>