<template>
    <transition name="slide-in">
        <div class="cookiewrapper" v-if="! (this.$cookies.isKey('accept_cookies') || dismissed)">
            <p>
                This website uses cookies to ensure you get the best experience.
                <a href="https://cookiesandyou.com" decorated>Learn more</a>
            </p>
            <b-button @click="dismiss">Accept and dismiss</b-button>
        </div>
    </transition>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
    name: "CookieConsent",
    components: {
        BButton
    },
    data() {
        return {
            dismissed: false
        }
    },
    methods: {
        dismiss() {
            this.$cookies.set('accept_cookies', true);
            this.dismissed = true
        }
    }
}
</script>

<style scoped>
.slide-in-leave-active {
    transform: none;
}
.slide-in-enter, .slide-in-leave-to {
    transform: translateX(30em);
}

.cookiewrapper {
    transition: all .2s;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 30em;
    max-width: 100vw;
    background-color: #2e3842;
    border: transparent 0 solid;
    border-top-left-radius: 4px;
}

.cookiewrapper p {
    padding: 2em 1em 1em 2em;
}

.cookiewrapper > .btn {
    width: 100%;
    height: 3em;
    background-color: #21b2a6;
    color: white;
    border-radius: 0;
    border-color: transparent;
}

.cookiewrapper > .btn:hover {
    color: white;
    background-color: #19877e;
}
</style>