module.exports = [
    {
        path: '/',
        component: () => import('@/components/pages/home/Home')
    },
    {
        path: '/your-ad',
        component:  () => import('@/components/pages/advertise/Advertise')
    },
    {
        path: '/projects',
        component:  () => import('@/components/pages/projects/Projects')
    },
    {
        path: '/termsofuse',
        component:  () => import('@/components/pages/imprint/TermsOfUse')
    },
    {
        path: '/privacypolicy',
        component:  () => import('@/components/pages/imprint/PrivacyPolicy')
    },
    {
        path: '/imprint',
        component:  () => import('@/components/pages/imprint/Imprint')
    },
    {
        path: '/download',
        component:  () => import('@/components/pages/download/Download')
    },
    {
        path: '/auth/login',
        component:  () => import('@/components/pages/authentication/Login'),
        meta: { bodyClass: 'show-recaptcha' }
    },
    {
        path: '/auth/register',
        component:  () => import('@/components/pages/authentication/Register'),
        meta: { bodyClass: 'show-recaptcha' }
    },
    {
        path: '/loading',
        component:  () => import('@/components/templates/LoadingScreen')
    },
    {
        path: '*',
        component: () => import('@/components/pages/Error404')
    }
];